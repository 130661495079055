<template>
    <div class="product-grid-item col--25">
        <div class="card card--product">
            <a class="card-figure" data-overlay="View Products" :href="site_url + '/product/' + category.replace('_', '-')+ '/' + product.url">
                <img class="card-img" :class="(category == 'fences' ? 'fence-crop' : '')"  :src="product.image" alt="">
            </a>
            <div class="card-body">

                <h3 class="card-title">
                    <a :href="site_url + '/product/' + category.replace('_', '-')+ '/' + product.url">
                        <template v-if="category == 'perforated'">{{ product.material }} - {{ product.perforation }}</template>
                        <template v-if="category == 'crimped_mesh'">Crimped Mesh</template>
                        <template v-if="category == 'expanded_metal'">{{ product.material }} - {{ product.ref_no }}</template>
                        <template v-if="category == 'weldmesh'">
                            <template v-if="product.type == 'weldmesh_panels'">Weldmesh Panels</template>
                            <template v-if="product.type == 'weldmesh_rolls'">Weldmesh Rolls</template>
                        </template>
                        <template v-if="category == 'profiles'">{{ product.material }}</template>
                        <template v-if="category == 'rod'">{{ product.material }}</template>
                        <template v-if="category == 'wire'">{{ product.material }}</template>
                        <template v-if="category == 'architectual_wire_cloth'">{{ product.material }}</template>
                        <template v-if="category == 'fences'">{{ product.material }} {{ (product.mesh_x_wire ? '- ' + product.mesh_x_wire : (product.panel_height_x_width ? '- ' + product.panel_height_x_width : (product.height ? '- ' + product.height : ''))) }}</template>
                        <template v-if="category == 'car_transporter_flooring'">{{ product.material }}</template>
                    </a>
                </h3>
                <p class="card-subtitle">
                    <template v-if="category == 'perforated'">{{ product.panel_size }} x {{ product.thickness }}</template>
                    <template v-if="category == 'crimped_mesh'">{{ product.aperture_size }} x {{ product.wire_diameter }}</template>
                    <template v-if="category == 'expanded_metal'">{{ product.standard_manufactured_size }}</template>
                    <template v-if="category == 'weldmesh'">{{ product.aperture_size_mm }} - {{product.wire_diameter_mm}}</template>
                    <template v-if="category == 'profiles'">{{ product.profile_type }}</template>
                    <template v-if="category == 'rod'">{{ product.diameter }}</template>
                    <template v-if="category == 'wire'">{{ product.wire_diameter }} mm</template>
                    <template v-if="category == 'architectual_wire_cloth'">{{ product.total_thickness }}</template>
                    <template v-if="category == 'fences'">{{ product.brand_name }}</template>
                    <template v-if="category == 'car_transporter_flooring'">{{ product.sheet_size }} - {{ product.total_thickness }}</template>
                </p>

                <div class="card-btns">
                    <a class="card-btn btn btn--small" :href="site_url + '/product/' + category.replace('_', '-')+ '/' + product.url">
                        View
                    </a>
                </div>

            </div>

        </div>
    </div>
</template>

<script>
    import api from './api.js';

    export default {
        props: [
            'product',
            'category'
        ],
        components: {},
        data: function () {
            return {
                site_url: site_url
            }
        },
        async mounted() {
            let _this = this;
        },
        methods: {
            /**
             * Add a product to the basket
             */
            add_product: async function () {
                await api.addProductToBasket(this.category, this.product.id).then(function (data) {
                    $('.js-basket-count').html(data.count);
                });

                return false;
            }
        }
    }
</script>