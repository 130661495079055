<template>
    <fieldset class="product-filter dropdown">
        <button class="product-filter-toggle js-dropdown-toggle" :data-toggle="'filter-'+ filter.slug">{{filter.name}}</button>

        <ul class="product-filter-items js-dropdown-body" :data-toggle="'filter-'+ filter.slug">
            <li class="product-filter-item product-filter-item--slider">
                <input type="text" :id="'slider-' + filter.slug" class="js-range-slider" name="my_range" value=""/>
            </li>
        </ul>

    </fieldset>
</template>

<script>
    import {EventBus} from './event-bus.js';

    export default {
        components: {},
        props: [
            'filter',
            'chosen_filters'
        ],
        data: function () {
            return {
                slider: false,
                from: false,
                to: false,
                doing_update: false
            }
        },
        async mounted() {
            let _this = this;

            // Set initial values
            this.from = this.filter.min;
            this.to = this.filter.max;

            // Init slider
            $("#slider-" + this.filter.slug).ionRangeSlider({
                type: "double",
                grid: false,
                step: 0.1,
                min: this.from,
                max: this.to,
                from: this.from,
                to: this.to,
                postfix: " <span>" + this.filter['measure'] +"</span>",
                onFinish: function (data) {
                    _this.finish();
                }
            }).on('change', this.change);

            // Store slider data
            this.slider = $("#slider-" + this.filter.slug).data("ionRangeSlider");
        },
        methods: {
            /**
             * Fires add/remove event on the event bus for the core product list
             * component to add/remove filter
             */
            change: function () {
                let slider = $("#slider-" + this.filter.slug);

                this.from = slider.data("from");
                this.to = slider.data("to");
            },

            /**
             * On finish of slider update
             */
            finish: function () {
                if (this.filter.min == this.from && this.filter.max == this.to) {
                    EventBus.$emit('remove-filter', {
                        filter: this.filter.slug,
                        name: this.filter.slug,
                        value: false
                    });
                } else {
                    EventBus.$emit('change-filter', {
                        filter: this.filter.slug,
                        name: this.filter.slug,
                        from: this.from,
                        to: this.to
                    });
                }
            }
        }
    }
</script>
