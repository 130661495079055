export default {
    addProductToBasket(category, product_id) {
        let form_data = new FormData;
        form_data.append('action', 'add_product_to_basket');
        form_data.append('category', category);
        form_data.append('product_id', product_id);

        return this.run(form_data);
    },
     getSearchData(search) {
        let form_data = new FormData;
        form_data.append('action', 'get_search_data');
        form_data.append('search', search);

        return this.run(form_data);
    },
    getCategoryData(category, chosen_filters) {
        let form_data = new FormData;
        form_data.append('action', 'get_category_data');
        form_data.append('category', category);
        form_data.append('chosen_filters', JSON.stringify(chosen_filters));

        return this.run(form_data);
    },
    run(form_data) {
        return axios.post(site_url + '/wp-admin/admin-ajax.php', form_data)
            .then(response => response.data).catch(function (error) {
                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else {
                    console.log(error);
                }
            });
    }
};