<template>
    <fieldset class="product-filter dropdown">
        <button class="product-filter-toggle js-dropdown-toggle" :data-toggle="'filter-'+ filter.slug">{{filter.name}}</button>

        <ul class="product-filter-items js-dropdown-body" :data-toggle="'filter-'+ filter.slug">
            <input :name="filter.slug" :ref="'selected_'+ filter.slug + '_search'" :id="filter.slug + '_search'" type="text" v-on:keyup="change">
        </ul>
    </fieldset>
</template>

<script>
    import {EventBus} from './event-bus.js';

    export default {
        components: {},
        props: [
            'filter',
            'chosen_filters'
        ],
        data: function () {
            return {}
        },
        methods: {
            /**
             * Fires add/remove event on the event bus for the core product list
             * component to pick up and add/remove filter
             */
            change: function (event) {
                let ref = 'selected_' + event.target.id;

                if (this.$refs[ref].value == '') {
                    EventBus.$emit('remove-filter', {
                        filter: this.filter.slug,
                        name: this.filter.slug,
                        value: false
                    });
                } else {
                    EventBus.$emit('change-filter', {
                        filter: this.filter.slug,
                        name: this.$refs[ref].name,
                        search: this.$refs[ref].value
                    });
                }
            },
        }
    }
</script>
