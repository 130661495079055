<template>
    <section v-if="this.ready">

        <nav class="tab-nav tab-nav--search">
            <ul>
                <li class="tab-nav-item" v-for="(data, index) in search_data.data" :key="index" v-if="search_data.data[index].total > 0">
                    <a class="tab-nav-link tab-nav-link--search" :class="(show_tab == index ? 'is-active' : '')" href="#" v-on:click="change_tab(index)"><strong>{{ data.title }}:</strong> {{ search_data.data[index].total }} results</a></li>
            </ul>
        </nav>

        <div class="tab-nav-content" v-for="(data, index) in search_data.data" :key="index" :id="index" v-if="search_data.data[index].total > 0 && show_tab == index">
            <template>
                <div class="product-grid-wrapper" style="width: 100%">
                    <div class="product-grid-inner">
                        <prw_product_card v-for="product in search_data.data[index].products" :product="product" :table_rows="data.table_rows" :category="index"></prw_product_card>
                    </div>
                </div>
            </template>
        </div>

        <div v-if="!show_tab">
            <p>No results found. Try a more detailed search.</p>
        </div>
    </section>
</template>

<script>
    import api from './api.js';

    var prw_table_header = require('./table_header.vue');
    var prw_table_row = require('./table_row.vue');
    var prw_product_card = require('./card-product.vue');

    export default {
        components: {
            prw_table_header,
            prw_table_row,
            prw_product_card
        },
        data: function () {
            return {
                ready: false,
                search_data: false,
                current_search: '',
                show_tab: false
            }
        },
        async mounted() {
            let _this = this;

            // Set search
            _this.current_search = search;

            // Refresh data
            this.refresh_data();

            /**
             * Show page
             */
            this.ready = true;
        },
        methods: {
            /**
             * Refresh category data
             */
            refresh_data: async function () {
                // Get data from api
                this.search_data = await api.getSearchData(this.current_search);

                // Get first active tab
                for (let i in this.search_data.data) {
                    if (this.search_data.data[i].total > 0) {
                        this.show_tab = i;
                        break;
                    }
                }
            },

            /**
             * Changes active tab on search
             *
             * @param i
             * @returns {boolean}
             */
            change_tab: function(i){
                this.show_tab = i;

                return false;
            }
        }
    }
</script>
