<template>
    <tr>
        <th class="product-list" v-for="t in table_headers">{{ t }}</th>
        <th class="product-list-add"></th>
    </tr>
</template>

<script>

    export default {
        props: [
            'category',
            'type',
            'table_headers'
        ],
        components: {},
        data: function () {
            return {}
        },
        async mounted() {
            let _this = this;
        },
        methods: {}
    }
</script>