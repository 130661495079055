<template>
    <tr>
        <td class="product-list" v-for="t in table_rows">{{ product[t] }}</td>

        <td class="product-list-add">
            <form class="product-list-number">
                <div class="number-field">

                    <a class="btn btn--tiny" type="button" :href="site_url + '/product/' + category.replace('_', '-')+ '/' + product.id">View</a>
                    <button class="btn btn--tiny btn--secondary" type="button" v-on:click="add_product(product.id)">Add</button>
                </div>
            </form>
        </td>
    </tr>
</template>

<script>
    import api from './api.js';

    export default {
        props: [
            'product',
            'category',
            'table_rows'
        ],
        components: {},
        data: function () {
            return {
                site_url: site_url
            }
        },
        async mounted() {
            let _this = this;
        },
        methods: {
            /**
             * Add a product to the basket
             */
            add_product: async function () {
                await api.addProductToBasket(this.category, this.product.id).then(function (data) {
                    $('.js-basket-count').html(data.count);
                });

                return false;
            }
        }
    }
</script>