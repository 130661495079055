// Enable dev tools
Vue.config.devtools = true;

// Product list
if ($('#product-list').length || $('#search').length) {

    // Load requirements
    var axios = require('axios');

    // Store requirements
    window.axios = axios;

    // Polyfill
    require('es6-promise/auto');

    // Import main component
    var ProductList = require('./components/product-list.vue');
    var Search = require('./components/search.vue');

    // Create new instance of vue and load your main component
    if($('#product-list').length ) {
        var app = new Vue(ProductList).$mount('#product-list');
    }

    if($('#search').length) {
        var search = new Vue(Search).$mount('#search');
    }
}