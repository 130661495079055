<template>
    <fieldset class="product-filter dropdown">
        <button class="product-filter-toggle js-dropdown-toggle" :data-toggle="'filter-'+ filter.slug">{{filter.name}}</button>

        <ul class="product-filter-items js-dropdown-body" :data-toggle="'filter-'+ filter.slug">
            <li class="product-filter-item" v-for="(option, index) in filter.options" v-if="option.name != 'Customer Requested Sizes' && option.name != 'Made To Order' && option.name != 'Made To Any Size' && option.name != 'Any Material'">
                <label :for="option.value.replace(' ', '_') + '_' + index">
                    <input class="product-filter-input product-filter-input--checkbox" :name="filter.slug + '[]'" :value="option.value" :id="option.value.replace(' ', '_') + '_' + index" type="checkbox"
                           :checked="option.checked" :ref="'selected_'+ option.value.replace(' ', '_') + '_' + index" v-on:click="change">
                    <span class="product-filter-label">{{ option.name }}</span>
                </label>
            </li>
        </ul>
    </fieldset>
</template>

<script>
    import {EventBus} from './event-bus.js';

    export default {
        components: {},
        props: [
            'filter',
            'chosen_filters'
        ],
        data: function () {
            return {}
        },
        methods: {
            /**
             * Fires add/remove event on the event bus for the core product list
             * component to pick up and add/remove filter
             */
            change: function (event) {
                let ref = 'selected_' + event.target.id;

                EventBus.$emit((this.$refs[ref][0].checked ? 'add-filter' : 'remove-filter'), {
                    filter: this.filter.slug,
                    name: this.$refs[ref][0].name,
                    value: this.$refs[ref][0].value
                });
            },
        }
    }
</script>
