<template>
    <section class="products-wrapper row" v-if="this.ready">

        <div class="products-wrapper-header">
            <h2 class="product-grid-title"><span>Showing</span> {{(typeof this.category_data.products === "undefined" ? 0 : category_data.products.length)}} Products {{(typeof
                    this.category_data.label === "undefined" ? '' : 'in ' +category_data.label)}}</h2>
        </div>

        <div class="product-filters-wrapper">

            <div class="product-filters-toggle btn btn--small btn--grey js-product-filters-toggle">View filters</div>

            <form class="product-filters js-product-filters" action="#">
                <template v-for="filter in filters">
                    <prw_radio_filter :filter="filter" :chosen_filters="chosen_filters" v-if="filter.type == 'radio'"></prw_radio_filter>
                    <prw_checkbox_filter :filter="filter" :chosen_filters="chosen_filters" v-if="filter.type == 'checkbox' && type_chosen"></prw_checkbox_filter>
                    <prw_slider_filter :filter="filter" :chosen_filters="chosen_filters" v-if="filter.type == 'slider' && type_chosen"></prw_slider_filter>
                    <prw_text_filter :filter="filter" :chosen_filters="chosen_filters" v-if="filter.type == 'text' && type_chosen"></prw_text_filter>
                </template>
                <div class="product-filter-btn">
                    <button class="product-filters-apply btn btn--green btn--small js-filters-close" type="button">Apply Filters</button>
                    <button class="btn btn--grey btn--small">Reset Filters</button>
                </div>
            </form>
        </div>

        <div class="product-grid-wrapper">
            <div class="product-grid-header">
                <h2 class="product-grid-title product-grid-title--desktop"><span>Showing</span> {{(typeof this.category_data.products === "undefined" ? 0 : category_data.products.length)}} Products {{(typeof
                    this.category_data.label === "undefined" ? '' : 'in ' +category_data.label)}}</h2>

                <div class="btn-group">
                    <button class="btn-group-btn btn btn--small btn--grey" :class="(view_type == 'grid' ? 'is-active' : '')" title="Grid View" v-on:click="change_view_type('grid')">
                        <i class="btn-group-icon icon-grid svg"></i>
                    </button>
                    <button class="btn-group-btn btn btn--small btn--grey" :class="(view_type == 'list' ? 'is-active' : '')" title="List View" v-on:click="change_view_type('list')">
                        <i class="btn-group-icon icon-list svg"></i>
                    </button>
                </div>
            </div>

            <div class="product-grid" v-if="type_chosen">
                <table class="product-list" border="0" v-if="view_type == 'list'">
                    <thead>
                    <prw_table_header :category="category" :type="(type_chosen ? chosen_filters['type'] : false)" :table_headers="category_data.table_headers"></prw_table_header>
                    </thead>
                    <tbody>
                    <prw_table_row v-for="product in category_data.products" :product="product" :table_rows="category_data.table_rows" :category="category"></prw_table_row>
                    </tbody>
                </table>
                <div class="product-grid-inner" v-else>
                    <prw_product_card v-for="product in category_data.products" :product="product" :table_rows="category_data.table_rows" :category="category"></prw_product_card>
                </div>
            </div>
            <div class="product-grid-description cms-content">
                <h2>{{category_data.label}}</h2>
                <p class="category-description">
                    {{category_data.products[0].desc}}
                </p>
            </div>
        </div>
    </section>
</template>

<script>
    import {EventBus} from './event-bus.js';
    import api from './api.js';

    var prw_checkbox_filter = require('./filter-checkbox.vue');
    var prw_text_filter = require('./filter-text.vue');
    var prw_radio_filter = require('./filter-radio.vue');
    var prw_slider_filter = require('./filter-slider.vue');
    var prw_table_header = require('./table_header.vue');
    var prw_table_row = require('./table_row.vue');
    var prw_product_card = require('./card-product.vue');

    export default {
        components: {
            prw_radio_filter,
            prw_checkbox_filter,
            prw_text_filter,
            prw_slider_filter,
            prw_table_header,
            prw_table_row,
            prw_product_card
        },
        data: function () {
            return {
                ready: false,
                category: false,
                category_data: [],
                filters: [],
                chosen_filters: {},
                type_chosen: false,
                view_type: 'grid'
            }
        },
        async mounted() {
            let _this = this;

            this.category_data = category_data;
            this.filters = this.category_data.filters;
            this.category = category_name;

            /**
             * Add filters to chosen filters array
             */
            this.refresh_chosen_categories();

            /**
             * Listen to event bus
             */
            EventBus.$on('change-filter', this.change_filter);
            EventBus.$on('add-filter', this.add_filter);
            EventBus.$on('remove-filter', this.remove_filter);

            /**
             * Set first type if isset
             */
            if (typeof this.filters[0]['options'][0].value !== undefined) {
                await this.add_filter({
                    filter: 'type',
                    name: 'type',
                    value: this.filters[0]['options'][0].value,
                });
            }

            /**
             * Show page
             */
            this.ready = true;
        },
        methods: {
            /**
             * Refresh category data
             */
            refresh_data: async function (refresh_filters) {
                if (refresh_filters) {
                    this.refresh_chosen_categories();
                }

                this.category_data = await api.getCategoryData(this.category, this.chosen_filters);
                this.filters = this.category_data.filters;

                if (refresh_filters) {
                    this.refresh_chosen_categories();
                }
            },

            /**
             * Refresh chosen categories
             */
            refresh_chosen_categories: function () {
                for (let index in this.chosen_filters) {
                    if (index !== 'type') {
                        this.chosen_filters[index] = [];
                    }
                }
            },

            /**
             * Change view type
             */
            change_view_type: function (type) {
                this.view_type = type;
            },

            /**
             * Adds filter to chosen filters array
             *
             * @param filter
             * @returns {Promise<void>}
             */
            add_filter: async function (filter) {

                console.log(filter);

                if (filter.filter == 'type') {
                    this.type_chosen = true;
                    this.chosen_filters[filter.filter] = [filter.value];
                } else {
                    if (typeof this.chosen_filters[filter.filter] === "undefined") {
                        this.chosen_filters[filter.filter] = [filter.value];
                    } else {
                        this.chosen_filters[filter.filter].push(filter.value);
                    }
                }

                await this.refresh_data((filter.filter == 'type'));
            },

            /**
             * Adds filter to chosen filters array
             *
             * @param filter
             * @returns {Promise<void>}
             */
            change_filter: async function (filter) {
                if (typeof filter.from !== "undefined") {
                    this.chosen_filters[filter.filter] = {
                        from: filter.from,
                        to: filter.to,
                    };
                } else {
                    this.chosen_filters[filter.filter] = {
                        search: filter.search,
                    };
                }

                await this.refresh_data(false);
            },

            /**
             * Removes filter to chosen filters array
             *
             * @param filter
             * @returns {Promise<void>}
             */
            remove_filter: async function (filter) {
                if (typeof this.chosen_filters[filter.filter] !== "undefined") {
                    for (let index = 0; index < this.chosen_filters[filter.filter].length; index++) {
                        if (this.chosen_filters[filter.filter][index] == filter.value) {
                            this.chosen_filters[filter.filter].splice(index, 1);
                        }
                    }

                    if (filter.filter == 'ref_no') {
                        this.chosen_filters[filter.filter] = [];
                    }
                }

                await this.refresh_data((filter.filter == 'type'));
            },
        }
    }

</script>
